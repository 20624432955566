<template>
    <div class="setPassword">
      <rxNavBar :title="enterType==='password'?'设置密码':'设置管理密码'"></rxNavBar>
        <div class="img_group">
            <img src="../../assets/images/head-quotation-mark.png">
            <div class="setPsd">{{enterType==='password'?'设置密码':'设置管理密码'}}</div>
        </div>
        <div class="passwordDiv">
            <div class="passwordDot" :class="{'redColor':(userPassword!='')}">•</div>
            <div class="text">输入密码</div>
            <div class="line">|</div>
            <input  :type="pwdType" onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"  placeholder="请输入密码" @on-change="userPassword"
                    maxlength="12" v-model="userPassword"  class="again"/>
            <img :src="seen?openeye:nopeneye" class="eye" alt="" @click="changeType()">
        </div>
            <div class="twice"  v-if="userPassword && !/^[A-Za-z0-9]{6,12}$/.test(userPassword)">请输入（6~12位的数字、字母组合）
            </div>
        <div class="passwordDiv" style="margin-top: 15px">
            <div class="passwordDot" :class="{'redColor':(userPasswordTwo!='')}">•</div>
            <div class="text">再次输入</div>
            <div class="line">|</div>
            <input  :type="pwdTypeTwo"  placeholder="请再次输入密码" @on-change="userPasswordTwo"

                    maxlength="12" v-model="userPasswordTwo"  class="again" />
            <img :src="seenTwo?openeyeTwo:nopeneyeTwo" class="eye" alt="" @click="changeTypeTwo()">
        </div>
            <div class="twice"  v-if="userPassword!==userPasswordTwo&userPassword!=''&userPasswordTwo!=''">两次输入不一致
            </div>
        <div>
            <van-button class="login" :class="checkSave()?'loginColor':''"
                        :disabled="!checkSave()" v-on:click="enterType==='password'?login():manageLogin()">
                确定
            </van-button>
        </div>
    </div>

</template>

<script>
    import { Button,Form ,Field} from 'vant';
    import {bindPassword,changeManagePassword} from "../../getData/getData";
    import {globaluserId, responseUtil} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    export default {
        name: "setPassword",
        data() {
            return {
                username: '',
                password: '',
                seen:'',
                pwdType: 'text', // 密码类型
                openeye: require('../../assets/images/hidden.png'), //图片地址
                nopeneye: require('../../assets/images/show.png'), //图片地址
                userPassword:'',
                seenTwo:'',
                pwdTypeTwo: 'password', // 密码类型
                openeyeTwo: require('../../assets/images/show.png'), //图片地址
                nopeneyeTwo: require('../../assets/images/hidden.png'), //图片地址
                userPasswordTwo:'',
              enterType:'',
            };
        },
        components: {
            [Button.name]: Button,
            [Form.name]: Form,
            [Field.name]: Field,
          rxNavBar
        },
        mounted() {
          this.enterType = this.$route.query.type
        },
        methods:{
            changeType() {
                this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
                this.seen = !this.seen;

            },
            changeTypeTwo() {
                this.pwdTypeTwo = this.pwdTypeTwo === 'password' ? 'text' : 'password';
                this.seenTwo = !this.seenTwo;
            },
            login:function () {
                let that=this;
                let passwordData={};
                passwordData.user_id=globaluserId();
                passwordData.password=that.userPassword;
                passwordData.passwordConfirm=that.userPasswordTwo;
                bindPassword(passwordData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                      if(200==response.status){
                        responseUtil.alertMsg(that,'绑定成功')
                        that.$router.go(-1)
                      }else {
                        responseUtil.alertMsg(that,'绑定失败')
                      }
                    })
                })
            },
            manageLogin:function () {
                let that=this;
                let passwordData={};
                passwordData.user_id=globaluserId();
                passwordData.newManagePassword=that.userPassword;
                passwordData.newConfirmManagePassword=that.userPasswordTwo;
                changeManagePassword(passwordData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(200==response.status){
                            responseUtil.alertMsg(that,'绑定成功')
                            that.$router.go(-1)
                        }else {
                            responseUtil.alertMsg(that,'绑定失败')
                        }
                    })
                })
            },
          checkSave(){
            // console.log(/^[A-Za-z0-9]{6,12}$/.test(this.userPasswordOne))   //true符合要求
            if(this.userPassword&&this.userPasswordTwo
                && this.userPassword==this.userPasswordTwo && /^[A-Za-z0-9]{6,12}$/.test(this.userPassword)){
              return true
            }
            return false
          },

        }
    }
</script>

<style scoped>
    .img_group{
        height: 75px;
    }
    .setPassword{
        width: 100%;
        position: absolute;
        left: 0;
        top: 30px;
    }
    img{
        width: 51.48px;
        height: 62.4px;
    }
    .setPsd{
      white-space: nowrap;
        width: 80px;
        height: 28px;
        color: rgba(34, 34, 34, 1);
        font-size: 20px;
        text-align: left;
        font-family: PingFangSC-Semibold;
        position: relative;
        font-weight: bold;
        left: 24px;
        bottom: 40px;
    }
    .passwordDiv{
      white-space: nowrap;
      display: flex;
      align-items: center;
        margin:0 15px;
        width: 92%;
        height: 50px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
    }
    .passwordDot{
        display: inline-block;
      /*padding-top: 10px;*/
      margin-right: 10px;
      margin-left: 12px;
      width: 6px;
      /*height: 6px;*/
        color: #B8B8B8;
        font-size: 27px;
        float: left;
    }
    .text{
        display: inline-block;
        float: left;
        padding-left: 6px;
        /*padding-top: 15px;*/
        font-weight: bold;
        /*width: 60px;*/
        height: 21px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        font-family: PingFangSC-Semibold;
    }
    .line {
        font-size: 15px;
        display: inline-block;
        color: #D8D8D8;
        float: left;
      padding: 0px 15px 0px 15px;
    }
    .passwordDiv input {
        width: 52%;
        float: left;
        border: none;
        font-size: 15px;
        vertical-align:middle;
    }
    .eye{
        width: 20px;
        height: 10px;
    }
    .login{
        margin: 260px 15px 0px 15px;
        width: 345px;
        height: 45px;
        font-size: 16px;
        background:rgba(184, 184, 184, 0.2);
        color: white;
        font-weight: bold;
        border-radius: 8px;
    }
    .redColor{
        color: red;
    }
    .loginColor{
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;
    }
    .twice{
      font-size: 12px;
      width: 92%;
      margin: 0.2rem auto;
      color: rgba(255, 93, 59, 1);
      text-align: right;
      font-family: PingFangSC-Regular;
    }

</style>
